/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 22/08/22
 * Time: 10:55 AM
 * Project: workaxis
 */
import React from 'react';
import "./style.css"
import {Link} from "react-router-dom";

export default function Landing() {

    const ourTeam = [
        {
            name: "Shyam Sundar",
            image: "https://ik.imagekit.io/kittydev/Workaxis/Team/boy_Xuam6LniN.jpeg",
            role: "CTO",
            link: "https://www.linkedin.com/in/shyamsundar-thandalamganesan-1b203313b/"
        },
        {
            name: "Chitradevi",
            image: "https://ik.imagekit.io/kittydev/Workaxis/Team/woman_3-nR4b1cT.jpeg",
            role: "CEO",
            link: "https://www.linkedin.com/in/chitradevi-balasubramani-091962128/"
        },
        {
            name: "Padma Dev E",
            image: "https://ik.imagekit.io/kittydev/Workaxis/Team/boy_Xuam6LniN.jpeg",
            role: "SDE",
            link: "https://www.linkedin.com/in/padmadev/"
        }
    ]
    const ourProducts = [
        {
            name: "Pyxis Social",
            description: "Get extensive performance metrics to build a stronger social media presence",
            image: "https://ik.imagekit.io/kittydev/Workaxis/Products/PyxisSocial_r0hVv5JQMW.png",
            link: "https://st.pyxissocial.com/"
        },
        {
            name: "Baylyn Crm",
            description: "Our marketing and sales platform simplifies customer information and documents for business users to more easily access and manage.",
            image: "https://ik.imagekit.io/kittydev/Workaxis/Products/baylyncrm_d6_ZVN-IH.webp",
            link: "https://www.baylyncrm.com/"
        }
    ]


    const testimony = [
        {
            name: "Shivam Sharma",
            image: "https://ik.imagekit.io/kittydev/Workaxis/Team/boy_Xuam6LniN.jpeg",
            designation: "Product Head - Pixis",
            command: "Thank you for taking the ownership of complete Analyze Module. You have been quite punctual with your timelines and It's been a great pleasure working with you.",
            time: ""
        },
        {
            name: "Lauren Moore",
            image: "https://ik.imagekit.io/kittydev/Workaxis/Team/woman_3-nR4b1cT.jpeg",
            designation: "",
            command: "Great provider! Shyamsundar was a great communicator and also very experienced, and he did the job quickly and well. He caught on really fast and was able to produce good quality code without too much direction. He was a pleasure to work with and I would absolutely hire him again. Great English speaker as well.",
            time: ""
        },
        {
            name: "Rishab",
            image: "https://ik.imagekit.io/kittydev/Workaxis/Team/boy_Xuam6LniN.jpeg",
            designation: "Director - Requity Applied Sciences Private Limited",
            command: "It was great working with Shyam. He is an excellent developer, he exceeded my expectations.",
            time: ""
        },
        {
            name: "Pugal Ramasamy",
            image: "https://ik.imagekit.io/kittydev/Workaxis/Team/boy_Xuam6LniN.jpeg",
            designation: "Lead Analyst - Dun & Bradstreet Corporation",
            command: "It was a good working experience with WorkAxis. Shyam and Padma have delivered the project on committed timeframe with expected quality. I really appreciate your work and effort. All the best.",
            time: ""
        }
    ]


    const workFlow = [
        {
            name: "Requirements Analysis",
            icon: "format_list_bulleted",
            bg: "wx-bg-blue-200"
        },
        {
            name: "UI/UX Design",
            icon: "design_services",
            bg: "wx-bg-amber-200"
        }, {
            name: "Prototyping",
            icon: "touch_app",
            bg: "wx-bg-cyan-200"
        }, {
            name: "Development",
            icon: "build",
            bg: "wx-bg-green-200"
        },
        {
            name: "Quality Assurance",
            icon: "verified_user",
            bg: "wx-bg-indigo-200"
        },
        {
            name: "Optimization and Enhancements",
            icon: "app_shortcut",
            bg: "wx-bg-red-200"
        },
        {
            name: "Launching App",
            icon: "rocket_launch",
            bg: "wx-bg-pink-200"
        }, {
            name: "Post Launch Maintenance",
            icon: "support",
            bg: "wx-bg-purple-200"
        }
    ]

    const techStack_1 = [
        {
            name: "Web App",
            image: "https://ik.imagekit.io/kittydev/Workaxis/tech-stack/web-solution_zwVcYF2sx.png"
        }, {
            name: "Mobile App",
            image: "https://ik.imagekit.io/kittydev/Workaxis/tech-stack/user-profile_mjRrtfD-Yn.png"
        },
        {
            name: "ML",
            image: "https://ik.imagekit.io/kittydev/Workaxis/tech-stack/ml_8Bft4syA4.png"
        },
    ]

    const techStack_2 = [
        {
            name: "SEO",
            image: "https://ik.imagekit.io/kittydev/Workaxis/tech-stack/seo_5o6gr09AW.png"
        },
        {
            name: "AWS",
            image: "https://ik.imagekit.io/kittydev/Workaxis/tech-stack/aws_D4RiBCq18.png"
        },
        {
            name: "React",
            image: "https://ik.imagekit.io/kittydev/Workaxis/tech-stack/react_7XM06pAFiu.png"
        },
        {
            name: "Firebase",
            image: "https://ik.imagekit.io/kittydev/Workaxis/tech-stack/firebase_eUE68vdVeH.png"
        }
    ]
    const techStack_3 = [
        {
            name: "RDBMS",
            image: "https://ik.imagekit.io/kittydev/Workaxis/tech-stack/database-management_Noc4zXPaHs.png"
        },
        {
            name: "Elastic Search",
            image: "https://ik.imagekit.io/kittydev/Workaxis/tech-stack/elastic-search_5_Dp7W3sNF.png"
        },
        {
            name: "Python",
            image: "https://ik.imagekit.io/kittydev/Workaxis/tech-stack/python_8Nt0x2Pqj.png"
        }
    ]

    const ourServices = [
        {
            name: "Web App",
            image: "flaticon-website"
        }, {
            name: "Mobile App",
            image: "flaticon-menu"
        },
        {
            name: "SEO",
            image: "flaticon-analysis"
        },
        {
            name: "ML",
            image: "flaticon-programing"
        },
        {
            name: "Q/A Testing",
            image: "flaticon-secure-shield"
        }
    ]

    return (
        <div>
            <div className="wx-main">
                <div className="wx-main-child">
                    <div className="wx-sub">
                        <div className="wx-parent-gap-top wx-parent-gap-9xl">
                            <div className="wx-child-center">
                                <div className="wx-parent-gap-bottom wx-parent-gap-2xl"><h2
                                    className="wx-text-lg-x">Discover New
                                    Dimension</h2></div>
                                <div className="wx-sub-text-xl wx-40-character">Technology Designed for You<br/></div>
                                <div className="wx-main-child">
                                    <div className="wx-child">
                                        <div className="wx-maze wx-mini-screen-col">
                                            {ourServices.map((service) => {
                                                let bgCSS = "flaticon wx-icon-services"
                                                bgCSS = `${bgCSS} ${service.image}`
                                                return (
                                                    <div className="wx-maze wx-column-base wx-child-center"
                                                         key={service.name}>
                                                        <div className={"wx-dp-xl wx-center-child-x"}>
                                                            <i className={bgCSS}></i>
                                                        </div>
                                                        <div>
                                                            <div
                                                                className="wx-x-font-title wx-fw-bold wx-sacrificer">{service.name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wx-layout-b">
                    <div className="wx-layout-9">
                        <div className="wx-layout-a wx-layout-1x1-x">
                            <div className="wx-layout-x-2">

                                <div className="wx-layout-a wx-layout-u-1">
                                    <div>
                                        <div className="wx-b-m-1-x wx-m-b-x-1"><h2
                                            className="wx-layout-c wx-20-character-x">Why <span
                                            className="wx-rainbow-green">us...?</span></h2></div>
                                        <div className="wx-layout-t-1 wx-48-character-x">We are a bootstrapped company
                                            that understands
                                            the needs of our clients and help them grow. We believe that complex
                                            business problems have simple, well-designed solutions and focus on
                                            highly-tailored IT solutions.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="wx-r-s-w-x">
                                <div className="wx-hc-hc-x wx-r-xs-x">
                                    <div className="wx-layout-a wx-layout-x-1 wx-layout-x-2 wx-layout-x-3">
                                        <div className="wx-layout-a wx-layout-1-x-1">
                                            <p className="wx-workflow-title wx-f-t-ft-x">You will have a delightful
                                                experience<br/></p>
                                        </div>
                                        <div className="wx-layout-a wx-layout-1-x-1">
                                            <p className="wx-f-t-ft-x wx-workflow-title">
                                                We will surpass your expectation<br/></p></div>
                                        <div className="wx-layout-a wx-layout-1-x-1">
                                            <p className="wx-f-t-ft-x wx-workflow-title">
                                                You will get a world class product<br/></p></div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="wx-main-child">
                    <div className="wx-sub-main-xl">
                        <div className="wx-parent-gap-bottom wx-parent-gap-5xl">
                            <div className="wx-child-center"><h2 className="wx-text-lg-x">Meet Our <span
                                className="wx-rainbow-red">Team</span></h2></div>
                        </div>
                        <div className="wx-fill-parent">
                            <div className="wx-child-layout-5x">
                                {ourTeam.map((member) => {
                                    return (
                                        <div key={member.name}>
                                            <div>
                                                <div className="wx-maze wx-column-base">
                                                    <a href={member.link}
                                                       className="wx-image-wrapper-x1 wx-circle wx-inline-block"><img
                                                        src={member.image}
                                                        loading={"lazy"}
                                                        alt={member.name}
                                                        className="wx-bg-image"/></a>
                                                    <div className="wx-maze wx-sub-main-xxs wx-child-center">
                                                        <p className="wx-x-font-title wx-fw-bold">
                                                            {member.name}</p>
                                                        <div>{member.role}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wx-main-child wx-breath">
                    <div className="wx-sub-main-xl">
                        <div className="wx-maze wx-layout-2x">
                            <div>
                                <div className="wx-hex">
                                    {techStack_1.map((tech) => {
                                        return (<div className="wx-mini-circle " key={tech.name}><img width={"auto"}
                                                                                                      height={"auto"}
                                                                                                      src={tech.image}
                                                                                                      loading="lazy"
                                                                                                      alt={tech.name}
                                                                                                      className="wx-image-container"/>
                                        </div>)
                                    })
                                    }
                                </div>
                                <div className="wx-hex">
                                    {techStack_2.map((tech) => {
                                        return (<div className="wx-mini-circle " key={tech.name}><img width={"auto"}
                                                                                                      height={"auto"}
                                                                                                      src={tech.image}
                                                                                                      loading="lazy"
                                                                                                      alt={tech.name}
                                                                                                      className="wx-image-container"/>
                                        </div>)
                                    })
                                    }
                                </div>
                                <div className="wx-hex">
                                    {techStack_3.map((tech) => {
                                        return (<div className="wx-mini-circle " key={tech.name}><img width={"auto"}
                                                                                                      height={"auto"}
                                                                                                      src={tech.image}
                                                                                                      loading="lazy"
                                                                                                      alt={tech.name}
                                                                                                      className="wx-image-container"/>
                                        </div>)
                                    })
                                    }
                                </div>
                            </div>
                            <div className="wx-max-width-xl">
                                <div className="wx-maze wx-sub-snake-md">
                                    <div>
                                        <div className="wx-parent-gap-bottom wx-parent-gap-base"><h2
                                            className="wx-text-sm-x wx-20-character">
                                            We are committed to create digital products that people <span
                                            className="wx-rainbow">love</span> to use.
                                        </h2>
                                        </div>
                                        <div className="wx-parent-gap-bottom wx-parent-gap-base"><p
                                            className="wx-sub-text-lg wx-36-character-x">OUR
                                            WORK IS SECOND TO NONE</p></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wx-main-child ">
                    <div className="wx-child-center">
                        <div className="wx-parent-gap-bottom wx-parent-gap-xl">
                            <div className="wx-child-center"><h2 className="wx-text-md-x wx-20-character">Clients</h2>
                            </div>
                        </div>
                        <div className="wx-m-w-2-x-x">
                            <div className="wx-teams">
                                <img
                                    src="https://ik.imagekit.io/kittydev/Workaxis/Clients/logo-dnb-gray_YuSKpyFxB.png"
                                    loading="lazy" alt="" className="wx-team"/>
                                <img
                                    src="https://ik.imagekit.io/kittydev/Workaxis/Clients/pixis_white_gray_nVg97pw3k.png"
                                    loading="lazy" alt="" className="wx-team"/>
                                <img
                                    src="https://ik.imagekit.io/kittydev/Workaxis/Clients/Baylyn_Media_gray_wHCEXujUG.png"
                                    loading="lazy" alt="" className="wx-team"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wx-main-child">
                    <div className="wx-sub-main-xl">
                        <div className="wx-parent-gap-bottom wx-parent-gap-4xl">
                            <div className="wx-parent-gap-bottom wx-parent-gap-xl">
                                <div className="wx-child-center"><h2
                                    className="wx-text-md-x wx-20-character">WorkFlow</h2>
                                </div>
                            </div>
                        </div>
                        <div className="wx-parent-gap-bottom wx-parent-gap-2xl">
                            <div className="wx-child-center"></div>
                        </div>
                        <div className="wx-maze wx-hex-4x">
                            {workFlow.map((flow) => {
                                let colorCSS = "wx-dp-md bg-red-200"
                                colorCSS = `${colorCSS} ${flow.bg}`
                                return (
                                    <div key={flow.icon}>
                                        <div className="wx-hex-card wx-circle-xs wx-shadowcons">
                                            <div className="wx-maze wx-column-base">
                                                <div className="wx-maze wx-sub-snake-sm wx-fill-parent wx-child-middle"
                                                     style={{gap: '10px'}}>
                                                    <div className={colorCSS}>
                                                        <div
                                                            className="wx-icons wx-txt-black">{flow.icon}
                                                        </div>
                                                    </div>
                                                    <p className={"wx-workflow-title"}>{flow.name}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="wx-main-child">
                    <div className="wx-main-lg">
                        <div className="wx-maze wx-uk">
                            <div className="wx-max-width-xl">
                                <div className="wx-maze wx-column-base ">
                                    <h3 className="wx-x-font-title"><span
                                        className="wx-rainbow-green">Our</span> Products</h3>
                                    <div>
                                        <span className={"wx-text-xs"}>Premium design and engineering, always on time and on budget.</span>
                                        <br/>
                                        <br/>
                                        <a href="mailto:hello@workaxis.in"
                                           className="wx-hyper-link wx-fw-bold">hello@workaxis.in</a>
                                    </div>
                                </div>
                            </div>
                            <div className="wx-fill-parent">
                                <div className="wx-bulk wx-2x">
                                    {ourProducts.map((product) => {
                                        return (
                                            <div className="wx-fillparent-child"
                                                 key={product.name}>
                                                <a href={product.link}
                                                   className="wx-vertical-dynamic wx-circle-xs zoom-in wx-inline-block">
                                                    <div className="wx-image-wrapper-x16_9"><img loading="lazy"
                                                                                                 src={product.image}
                                                                                                 alt={product.name}
                                                                                                 className="wx-bg-image"/>
                                                    </div>
                                                    <div className="wx-bulk-info">
                                                        <div>
                                                            <div
                                                                className="wx-sub-text-xs wx-fw-bold">{product.name}</div>
                                                            <p className="wx-x-font-title wx-inner-gap-16 wx-fw-medium">{product.description}</p>
                                                        </div>
                                                    </div>
                                                </a></div>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wx-overflow-hidden">
                    <div className="wx-main-child">
                        <div className="wx-slider">
                            <div className="wx-slider-holder">
                                {testimony.map((test) => {
                                    return (
                                        <div className="wx-post-card" key={"wx_" + test.name}>
                                            <div>
                                                <div className="wx-maze wx-sub-snake-sm wx-child-middle">
                                                    <img
                                                        src={test.image}
                                                        loading="eager" sizes="36px"
                                                        alt={test.name} className="wx-dp-md"/>
                                                    <div className="wx-sacrificer">
                                                        <div className="wx-text-link-disabled">{test.name}</div>
                                                        <div className="wx-min-s-text-sm">{test.designation}</div>
                                                    </div>
                                                </div>
                                                <div className="wx-parent-gap-top wx-parent-gap-base">
                                                    <p>&quot;{test.command}&quot;</p>
                                                </div>
                                            </div>
                                            <div className="wx-min-s-text-sm">{test.time}</div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                            <div aria-hidden="true" className="wx-slider-holder">
                                {testimony.map((test) => {
                                    return (
                                        <div className="wx-post-card" key={"wx_" + test.name}>
                                            <div>
                                                <div className="wx-maze wx-sub-snake-sm wx-child-middle">
                                                    <img
                                                        src={test.image}
                                                        loading="eager" sizes="36px"
                                                        alt={test.name} className="wx-dp-md"/>
                                                    <div className="wx-sacrificer">
                                                        <div className="wx-text-link-disabled">{test.name}</div>
                                                        <div className="wx-min-s-text-sm">{test.designation}</div>
                                                    </div>
                                                </div>
                                                <div className="wx-parent-gap-top wx-parent-gap-base">
                                                    <p>&quot;{test.command}&quot;</p>
                                                </div>
                                            </div>
                                            <div className="wx-min-s-text-sm">{test.time}</div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                        </div>

                    </div>
                </div>
                <div className="wx-footer-x1">
                    <div className="wx-main-lg">
                        <div className="wx-maze wx-foot-prints">
                            <div className="wx-footer-x1b">
                                <div className="wx-maze wx-footer-x">
                                    <h2 className="wx-text-xs wx-text-color-white">
                                        we would like to work with you on your next big idea
                                    </h2>
                                    <div className="wx-maze wx-sub-snake-base">
                                        <a href="mailto:hello@workaxis.in"
                                           className="wx-link-connector wx-bg-blue-800 wx-inline-block">
                                            <div className="wx-button-label-x">Email us</div>
                                            <div className="wx-button-hover-background-x"></div>
                                        </a>
                                        <Link to={"/contact"}
                                              className="wx-link-connector wx-bg-blue-800 wx-inline-block">
                                            <div className="wx-button-label-x">Contact us</div>
                                            <div className="wx-button-hover-background-x"></div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}