/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 22/08/22
 * Time: 11:57 AM
 * Project: workaxis
 */
import React from 'react';
import "./style.css"
import {Link} from "react-router-dom";

export default function NavigationFooter({children}) {
    let year = new Date().getFullYear()
    return (
        <div>
            <video className="wx-x-video-bg"
                   poster={"https://ik.imagekit.io/kittydev/Workaxis/WX-landing-Assets/bg_placeholder_VnF9yjLrA.jpeg"}/>
            <div className="wx-main wx-base-bg ">
                <div className="wx-navigation-x wx-navigation">
                    <div className="wx-maze wx-navigation-list"><Link to={"/"} className="wx-navigation-brand-image wx-navigation-brand"><img
                        height={100} width={100}
                        src="https://ik.imagekit.io/kittydev/Workaxis/Logo/workaxis_light_3W7qnudgAt.png"
                        loading="lazy" alt="WorkAxis-Brand-Icon" className="wx-image-container"/></Link>
                    </div>
                    <div className="wx-navigation-bar-shadow"></div>
                </div>


                {children}


                <div className="wx-footer-x1">
                    <div className="wx-main-lg">
                        <div className="wx-maze wx-foot-prints">
                            <div className="wx-foot-info">
                                <div className="wx-maze wx-sub-snake-base column-mobile">
                                    <div className="wx-footer">
                                        WorkAxis © Copyright {year} | All Rights Reserved.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}