/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 22/08/22
 * Time: 12:45 PM
 * Project: workaxis
 */
import React from 'react';
import "./style.css"

export default function Contact() {
    return (
        <div className="wx-main-child" style={{minHeight: "100vh"}}>
            <div className="wx-sub-main-xl">
                <div className="wx-maze wx-layout-2x">
                    <div className={"margin-left-mobile"}>
                        <div className="wx-parent-gap-bottom wx-parent-gap-4xl">
                            <h1 className="wx-text-md-x">Need help with something?</h1>
                        </div>
                        <div className="wx-maze wx-column-base fade-in-on-scroll">
                            <h3 className="wx-x-font-title">Email us at :
                                <a href="mailto:hello@workaxis.in" className="wx-hyper-link"> hello@workaxis.in</a>
                            </h3>
                            <h3 className="wx-x-font-title">Contact us at :
                                <a href="tel:+918754533683" className="wx-hyper-link"> (+91) 8754533683</a>
                            </h3>
                            <h3 className="wx-x-font-title">Address :
                                <br/>Sivanandha Street, Vellakkal, Chennai, Tamil Nadu 600117
                            </h3>

                        </div>
                    </div>
                    <div className={"wx-contact-map-holder wx-circle-xs"}>
                        <iframe className={"wx-contact-map"}
                                height={300}
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15554.421536301019!2d80.18009!3d12.9330654!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd2053fbd8d930bc4!2sWORKAXIS%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sin!4v1661154955989!5m2!1sen!2sin"
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
            <div className={"contact-form-container"}>
                <div className={"contact-form"}>
                    <a className="biw" id="w-crm-contact-widget"
                       href="https://app.antcrm.co/lead/book/shyamsundar.tg/?1a1e19=4642&embed=true"
                       data-modal="no"
                       server-url="https://app-backend-dev.antcrm.co/api/v1/leads-source-analytics/"
                       org-map="58c10038-d319-4ca3-9404-3b0831e8c150">contact</a>
                </div>
            </div>
        </div>
    );
}