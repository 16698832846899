import './App.css';
import NavigationFooter from "./Pages/Landing/NavigationFooter";
import {Route, Routes} from "react-router-dom";
import Landing from "./Pages/Landing/Landing";
import Contact from "./Pages/Landing/Contact";
import ScrollToTop from "./Pages/utils/ScrollToTop";

function App() {

    return (

        <ScrollToTop>
            <Routes>
                <Route key={"landing"} path="" element={<NavigationFooter>
                    <Landing/>
                </NavigationFooter>}/>
                <Route key={"contact"} path="/contact" element={<NavigationFooter>
                    <Contact/>
                </NavigationFooter>}/>
            </Routes>
        </ScrollToTop>

    );
}


export default App;
